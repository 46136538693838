import React, { useEffect, useState } from "react"
import LatestNews from "../containers/latest-news"
import ThreeColumnGallery from "../containers/three-column-gallery"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { OutlinedButton } from "../components/buttons"
// TODO: use heroicons
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// FOR EVENT Detail
import Blocks from "editorjs-blocks-react-renderer"
import { EventDetailTextTwo, BlogDetailTextOne } from "../components/typography"
import Instagram from "../containers/instagram"

const BlogDetail = ({ data, location }) => {
  const myData =
    data.strapiBlog?.content?.data?.childMarkdownRemark &&
    JSON.parse(
      data.strapiBlog?.content?.data?.childMarkdownRemark?.rawMarkdownBody
    )

  console.log(myData)
  const mainImage = getImage(data.strapiBlog.featuredImage.localFile)
  const imageArray = data.strapiBlog.images
  function Image({ data }) {
    console.log(data, imageArray)
    const filteredImage = imageArray.find(image => image.url === data.file.url)
    return (
      <>
        <GatsbyImage image={filteredImage.childImageSharp.gatsbyImageData} />
      </>
    )
  }
  return (
    <Layout
      ctaColor="bg-yellowdark-50"
      textCtaColor="text-fontpurple-50"
      location={location}
      fullHeader
      title={data.strapiBlog?.title}
    >
      <div className="bg-white pb-20">
        <Seo title={data.strapiBlog?.title} />
        <GatsbyImage
          className="h-96 lg:h-[70vh] w-full"
          objectFit="cover"
          image={mainImage}
        />
        <div
          className="relative  bg-white  rounded-2xl lg:rounded-4xl text-white text-center  -mt-12 max-w-7xl mx-auto
                 px-4
                 py-6
                 sm:px-20
                 sm:py-10
                 flex
                 flex-col
                 md:flex-row
                 flex-nowrap
                 "
        >
          <div
            className="flex flex-row justify-between md:flex-col md:justify-start md:mt-10 md:ml-10 md:order-2
              flex-nowrap
              justify-start
              items-center
              mb-8"
          >
            <p className="block text-fontpurple-50 font-inter text-xs lg:text-sm font-thin md:mb-4">
              {new Date(data.strapiBlog?.createdAt).toLocaleString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </p>
            <OutlinedButton additionalClassName="w-fit h-fit bg-fontpurple-50">
              <span className="md:inline-block py-1 px-2 font-primary-bold text-xs">
                {data.strapiBlog?.category?.categoryName}
              </span>
            </OutlinedButton>
          </div>
          <div className="mt-4 lg:mt-10 lg:pr-14">
            <BlogDetailTextOne additionalClassName="text-left text-fontpurple-50 mb-12 font-primary-bold text-3xl lg:text-[50px] lg:!leading-[56px]">
              {data.strapiBlog?.title}
            </BlogDetailTextOne>
            <EventDetailTextTwo additionalClassName="text-left mb-12 font-primary-bold">
              {data.strapiBlog?.subtitle}
            </EventDetailTextTwo>
            <Blocks
              data={myData}
              renderers={{
                image: Image,
              }}
              className="w-9/12"
              config={{
                code: {
                  className: "language-js",
                },
                delimiter: {
                  className: "border border-2 w-16 mx-auto",
                },
                embed: {
                  className: "border-0",
                },
                header: {
                  className:
                    "text-left text-xl text-black font-primary-bold pb-6",
                },

                image: {
                  className: "w-full pb-16 mb-10",
                  actionsClassNames: {
                    stretched: "w-full h-80 object-cover",
                    withBorder: "border border-2",
                    withBackground: "p-2",
                  },
                },
                list: {
                  className: "list-inside",
                },
                paragraph: {
                  className: "text-left text-black text-lg pb-10 font-inter",
                },
                figcaption: {
                  className: "hidden",
                },
                quote: {
                  className: "py-3 px-5 italic font-serif",
                },
                table: {
                  className: "table-auto",
                },
              }}
            />
          </div>
        </div>
      </div>
      <LatestNews
        eventsCarouselBg="white"
        eventsCarouselFontColor="#009ADE"
        cardBorderColorClass="border-white-50"
        cardFontColorClass="text-white"
        type={"mainPage"}
        blogs={data.allStrapiBlog.edges}
      />
      <ThreeColumnGallery
        bgGallery="bg-black"
        type={"mainPage"}
        titleColor={"text-white"}
        galleries={data.allStrapiGallery.nodes}
        galleryCtaTextColor={"text-white"}
        galleryCtaBorderColor={"border-white"}
      />
      <Instagram type={"mainPage"} title={"zrcebeach_official"} />
    </Layout>
  )
}

export default BlogDetail

export const blogQuery = graphql`
  query blog($id: Int!) {
    strapiBlog(strapi_id: { eq: $id }) {
      id
      slug
      title
      subtitle
      createdAt
      featuredImage {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80)
          }
        }
      }
      category {
        categoryName
      }
      images {
        id
        url
        childImageSharp {
          id
          gatsbyImageData
        }
      }
      # contentJsonFormat {
      #   time
      #   version
      #   blocks {
      #     type
      #     data {
      #       caption
      #       stretched
      #       withBorder
      #       withBackground
      #       text
      #       level
      #       file {
      #         url
      #         formats {
      #           large {
      #             url
      #           }
      #         }
      #       }
      #     }
      #   }
      # }
      content {
        data {
          childMarkdownRemark {
            id
            rawMarkdownBody
          }
        }
      }
    }
    allStrapiBlog {
      edges {
        node {
          slug
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 100)
              }
            }
          }
          category {
            slug
          }
          title
          publishedAt
        }
      }
    }
    allStrapiGallery(limit: 3) {
      nodes {
        id
        title
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 10)
            }
          }
        }
      }
    }
  }
`
